<template>
  <el-dialog
    id="ImportOrgWithPlanDialog"
    ref="dialogWrapper"
    :title="title"
    :visible.sync="dialogShow"
    modal
    center
    :close-on-click-modal="false"
    width="90%"
    top="5vh"
    close-on-press-escape
    class="my-dialog abow_dialog"
    append-to-body
    @open="onOpen"
    @opened="onOpened"
    @close="onClose"
    @closed="onClosed"
    @keydown.enter.native="onFormEnter"
  >
    <el-header class="header2" height="48"
      ><el-row type="flex" justify="start" align="center">
        <TIButton icon="plus_green" @onClick="onSelectResponsed" :label="$t('Виділити усі розпізнані')" />
        <TIButton icon="plus_green" @onClick="onSelectResponsedNew" :label="$t('Виділити нові розпізнані')" />
        <TIButton icon="plus_green" @onClick="onUnselectAll" :label="$t('Зняти виділення')" />
        <TSeparator />
        <div style="padding-left:8px;padding-right:8px;">{{ $t("Правило застосування плану") }}:</div>
        <el-select v-model="model.importPlanPolicy" style="width:350px">
          <el-option v-for="item in selectOptions.importPlanPolicy" :key="item.value" :label="$t(item.label)" :value="item.value"> </el-option>
        </el-select>
      </el-row>
    </el-header>
    <el-container v-loading="loading" :element-loading-text="loadingText" style="padding-top:16px;">
      <div ref="flex_container" class="flex_container">
        <ag-grid-vue
          ref="OrgTable"
          style="width: 100%; height: 100%;"
          class="ag-theme-alpine"
          rowSelection="multiple"
          :defaultColDef="defaultColDef"
          :columnDefs="columns"
          :headerHeight="32"
          :rowData="orgs"
          :gridOptions="gridOptions"
          :frameworkComponents="frameworkComponents"
          @selection-changed="onGridSelectionChanged"
          :overlayNoRowsTemplate="noRowsToShowTemplate"
          @sortChanged="onGridColumnsChange"
          @columnResized="onGridColumnsChange"
          @columnMoved="onGridColumnsChange"
          @displayedColumnsChanged="onGridColumnsChange"
          @gridReady="onGridReady"
          :modules="modules"
          :localeTextFunc="gridLocale"
          :enableBrowserTooltips="true"
          :sideBar="sideBar"
          :pivotMode="false"
        >
        </ag-grid-vue>
      </div>
    </el-container>
    <span slot="footer" class="dialog-footer">
      <el-button native-type="submit" type="success" @click="onCommit" class="apply-button" :disabled="!selectedRows || selectedRows.length == 0">{{
        $t("Застосувати")
      }}</el-button>
      <el-button type="text" @click="dialogShow = false" class="cancel-button">{{ $t("Відмінити") }}</el-button>
    </span>
    <Info />
  </el-dialog>
</template>

<script>
import { bus } from "@/main";
import { _ } from "vue-underscore";
import { AgGridVue } from "@ag-grid-community/vue";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import Icon from "@/components/IconPack";
import TIButton from "@/components/ToolbarIconButton";
import TSeparator from "@/components/ToolbarSeparator";
import GridTotalBottomRow from "@/components/GridTotalBottomRow.js";
import GridCellWithIcons from "@/components/GridCellWithIcons.js";

function parseError(error) {
  if (typeof error == "string") {
    return error;
  } else if (Array.isArray(error)) {
    return error.join("; ");
  } else return error;
}

export default {
  name: "ImportOrgWithPlan",
  components: { AgGridVue, Splitpanes, Pane, Icon, TIButton, TSeparator, Info: () => import("@/components/forms/Info") },
  data() {
    var that = this;
    return {
      frameworkComponents: { GridTotalBottomRow, GridCellWithIcons },
      modules: AllModules,
      dialogShow: false,
      dialogHeight: 0,
      resizeObserver: null,
      title: $tt("Пошук"),
      target: null,
      filterString: "",
      orgExists: [],
      noRowsToShowTemplate: `<div><img src="${require("../../assets/icons/no_data.svg")}"></div>`,
      selectedRows: null,
      keyUpTimer: null,
      gridApi: null,
      operation: "",
      model: {},
      initModel: {},
      gridColumnApi: null,
      gridOptions: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data.importId;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
            {
              statusPanel: "GridTotalBottomRow",
              align: "left",
              items: [{ label: $tt("Розпізнано"), value: () => this.$store.getters["orgs/importStore"].filter((item) => item.drugsId).length }],
            },
          ],
        },
        getRowStyle: function(params) {
          if (that.orgExists.find((item) => item.axiomaId == params.data.axiomaId)) return { "font-weight": "bold" };
          else return null;
        },
      },
      defaultColDef: {
        suppressNavigable: true,
        cellClass: "no-border",
        resizable: true,
        sortable: true,
        tooltipValueGetter: function(params) {
          return params.value;
        },
        floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
        suppressMenu: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      sideBar: {
        toolPanels: ["columns", "filters"],
        position: "left",
        hiddenByDefault: false,
      },
      columns: [
        {
          headerName: "Axioma ID",
          field: "axiomaId",
          width: "200px",
          headerCheckboxSelection: true,
          checkboxSelection: true,
          headerCheckboxSelectionFilteredOnly: true,
          comparator: function(valueA, valueB, nodeA, nodeB, isInverted) {
            if (!nodeA || !nodeA.data) return 0;
            if (!nodeB || !nodeB.data) return 0;
            if (nodeA.data.axiomaId && !nodeB.data.axiomaId) return 1;
            if (!nodeA.data.axiomaId && nodeB.data.axiomaId) return -1;
            if (nodeA.data.name && nodeB.data.name) return nodeA.data.name > nodeB.data.name ? 1 : -1;
            return nodeA.data.srcName > nodeB.data.srcName ? 1 : -1;
          },
          sort: "asc",
          cellRenderer: "GridCellWithIcons",
          cellIcons: [
            {
              icon: "info-circle",
              type: "fas el-icon-fa-",
              color: function(data) {
                if (data && data.node.data && data.node.data.axiomaId) return "green";
                else return "red";
              },
              iconClass: "icon-action",
              title: function(data) {
                if (data && data.node.data && data.node.data.axiomaId) return $tt("Розпізнано");
                else if (data && data.node.data && data.node.data.srcName) return $tt("Знаходиться в процесі розпізнавання");
                else return $tt("Не розпізнано");
              },
              onClick: (params) => {
                this.showInfo(null, params.data);
              },
            },
          ],
        },
        {
          headerName: $tt("Похідна AxiomaID"),
          field: "srcAxiomaId",
          flex: 1,
          type: "rightAligned",
        },
        {
          headerName: $tt("Назва"),
          field: "name",
          flex: 2,
        },
        {
          headerName: $tt("Адреса"),
          field: "address",
          flex: 2,
        },
        {
          headerName: $tt("Похідна ЄДРПОУ"),
          field: "srcOkpo",
          flex: 1,
          type: "rightAligned",
        },
        {
          headerName: $tt("ЄДРПОУ"),
          field: "okpo",
          flex: 1,
          type: "rightAligned",
        },
        {
          headerName: $tt("Юридична особа"),
          field: "isEntity",
          width: "75px",
          valueGetter: (data) => {
            return data && data.data && data.data.okpo ? $tt("Так") : $tt("Ні");
          },
        },
        {
          headerName: $tt("Похідний план"),
          field: "plan",
          width: "100px",
          type: "rightAligned",
        },
        {
          headerName: $tt("Статус"),
          field: "status",
          width: "75px",
          valueGetter: (data) => {
            return data && data.data && data.data.status ? $tt("Активний") : "-";
          },
        },
      ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
    };
  },
  mounted() {},

  created() {
    bus.$on("forms.orgs.import.show", (data) => {
      if (!this.dialogShow) {
        this.title = (data && data.title) || this.title;
        this.filterString = "";
        this.initModel = _.extend(
          { pageNumber: 1, rowNumber: 1, axiomaIdColNumber: 1, okpoColNumber: 2, planColNumber: 3, importPlanPolicy: "clear" },
          data.initModel
        );
        this.dialogShow = true;
        this.orgExists = data.orgExists ? data.orgExists : [];
      }
    });
    bus.$on("forms.orgs.import.hide", () => {
      this.dialogShow = false;
      this.resizeObserver = null;
    });
  },
  computed: {
    orgs() {
      return this.$store.getters["orgs/importStore"];
    },
    loading() {
      return this.$store.getters["orgs/wait"]();
    },
    loadingText() {
      return this.$store.getters["orgs/waitStatus"]();
    },
    selectOptions() {
      return this.$store.getters["main/selectionOptions"].importOrgWithPlan;
    },
  },
  watch: {
    dialogHeight(value) {
      var containerHeight = value - 48 - 72 - 64;
      this.$refs.flex_container.style.height = `${containerHeight}px`;
    },
  },
  methods: {
    onGridReady() {
      this.gridApi = this.gridOptions.api;
      this.gridColumnApi = this.gridOptions.columnApi;
      var state = this.$store.getters["main/userSettings"]("ImportOrgGridColumnState");
      if (state) this.gridColumnApi.setColumnState(state);
    },
    observeHeight() {
      var that = this;
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(function() {
          if (that.$refs.dialogWrapper && that.$refs.dialogWrapper.$el) that.dialogHeight = that.$refs.dialogWrapper.$el.children[0].clientHeight;
        });
        this.resizeObserver.observe(this.$el.children[0]);
      }
    },
    unobserveHeight() {
      this.resizeObserver && this.resizeObserver.unobserve(this.$el.children[0]);
      this.resizeObserver = null;
      this.dialogHeight = 0;
    },
    resetFilter(event) {
      if (this.filterString != "") {
        event && event.stopPropagation();
        if (this.loading) {
          this.$store.dispatch("main/cancelAllRequests");
        } else this.$refs.filter.clear();
      }
    },
    onOpen() {
      this.observeHeight();
      var importSettings = this.$store.getters["main/userSettings"]("importOrgWithPlanFromXLS");
      this.model = _.extend({}, this.initModel, importSettings);
    },
    onOpened() {},
    onClose() {
      //this.$store.dispatch("main/cancelAllRequests");
      this.dialogShow = false;
    },
    onClosed() {
      this.unobserveHeight();
    },
    onCommit() {
      if (this.selectedRows && this.selectedRows.length > 0) {
        this.dialogShow = false;
        this.$emit("import-commit", { data: this.selectedRows, model: this.model });
      } else this.$message($tt("Будь ласка, оберіть один або декілька рядків в таблиці!"));
    },
    onGridSelectionChanged() {
      var rows = this.gridOptions.api.getSelectedRows();
      if (rows && rows.length > 0) {
        this.selectedRows = rows;
      } else {
        this.selectedRows = null;
      }
    },
    onFormEnter() {
      this.onAdd();
    },
    onGridColumnsChange() {
      var that = this;
      if (this.changeColumnsTimer) clearTimeout(this.changeColumnsTimer);
      this.changeColumnsTimer = setTimeout(() => {
        var state = that.gridColumnApi.getColumnState();
        clearTimeout(that.changeColumnsTimer);
        that.$store.dispatch("main/updateUserSettings", { ImportOrgGridColumnState: state });
      }, 500);
    },
    gridLocale(key, defaultValue) {
      var localized = $tt("grid." + key);
      return localized ? localized : defaultValue;
    },
    onSelectResponsed() {
      this.gridApi.deselectAll();
      this.gridOptions.api.forEachNodeAfterFilter((node) => {
        if (node.data.axiomaId) node.setSelected(true);
      });
    },
    onSelectResponsedNew() {
      this.gridApi.deselectAll();
      var that = this;
      this.gridOptions.api.forEachNodeAfterFilter((node) => {
        if (node.data.axiomaId && !that.orgExists.find((item) => item.axiomaId == node.data.axiomaId)) node.setSelected(true);
      });
    },
    onUnselectAll() {
      this.gridApi.deselectAll();
    },
    onFindOrgCommit(data) {
      if (data.result && data.result.length > 0) {
      }
    },
    showInfo(event, data) {
      if (data) {
        var info = [];
        info.push({ name: $tt("Номер рядка"), value: data.srcRowNo ? data.srcRowNo : "-" });
        info.push({ name: $tt("Зміст"), value: data.srcRow ? data.srcRow : "-" });
        bus.$emit("forms.info.show", {
          title: $tt("Інформація про організацію"),
          initModel: { info: data.axiomaId ? $tt("Розпізнано рядок!") : $tt("Не розпізнано рядок!"), data: info },
        });
      }
    },
  },
};
</script>

<style lang="scss" scope>
#ImportOrgWithPlanDialog .header2 {
  background: #fff;
  line-height: 32px;
  border-bottom: 1px solid #ccc !important;
  padding: 0 !important;
  align-items: center;
  margin-left: -16px;
  margin-right: -16px;
}

#ImportOrgWithPlanDialog.my-dialog .el-dialog--center .el-dialog__body {
  padding: 0px 16px 0 16px !important;
}
</style>
